<template>
  <div class="imageVariation-wrapper">
    <el-row :gutter="15">
      <el-col :span="10" :offset="2">
        <el-card class="box-card">
          <el-form ref="form" label-width="80px" label-position="left">
            <el-form-item label="输出尺寸">
              <el-select v-model="outputsize" placeholder="选择输出尺寸">
                <el-option label="最大边256px" :value="256"></el-option>
                <el-option label="最大边512px" :value="512"></el-option>
                <el-option label="最大边1024px" :value="1024"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择图片">
              <el-upload
                class="avatar-uploader"
                action="/"
                :show-file-list="false"
                :before-upload="select_source_img"
              >
                <canvas id="previewCavars" v-show="source_img"></canvas>
                <i
                  v-show="!source_img"
                  class="el-icon-plus avatar-uploader-icon"
                ></i>
              </el-upload>
            </el-form-item>
            <el-form-item>
              <el-button
                style="width: 100%"
                type="success"
                @click="submit"
                :loading="loading"
                >消耗{{ cost }}字，开始仿图</el-button
              >
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
      <!--上传区域-->
      <el-col :span="10"> 
        <el-card class="box-card">
          
          <div class="preview" v-if="ret">
            <img :src="RetUrl">
          </div>
          <div class="place-holder" v-else></div>
        </el-card>
      </el-col>
      <!--展示区域-->
    </el-row>
  </div>
</template>

<script>
import ImagePreprocess from "@/utils/ImagePreprocess.js";
import { ImageVariation } from "@/api/gpt_img_gen.js";
export default {
  data() {
    return {
      loading: false,
      source_img: undefined,
      outputsize: 512,
      ret:undefined
    };
  },
  methods: {
    select_source_img(file) {
      //选中源文件
      this.source_img = file;
      this.create_source(file);
    },
    create_source(file) {
      //创建上传文件
      const mGen = new ImagePreprocess("previewCavars", this.outputsize);
      mGen.LoadFile(file).then((resp) => console.log(resp));
    },
    submit() {
      //上传
      const mGen = new ImagePreprocess("previewCavars", this.outputsize);
      mGen.LoadFile(this.source_img).then(({padding, file_blob}) => {
        const formData = new FormData();
        formData.append("file", file_blob);
        formData.append(
          "filename",
          this.source_img.name.split(".")[0] + ".png"
        );
        formData.append("size", this.outputsize);
        formData.append("padding", padding.join(","));
        this.loading = true;
        ImageVariation(formData)
          .then((data) => {
            this.ret = data;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
  },
  computed: {
    RetUrl(){
      let url = this.ret.result_url
      let padding= this.ret.padding.split(',')
      // url = "https://cdn.weippstore.com/c4ca4238a0b923820dcc509a6f75849b/34470ffc138e33abcbe3b3515a7f3f17.png"
      // padding =padding.split(",")

      

      return `${url}?imageMogr2/crop/!${padding[2]}x${padding[3]}a${padding[0]}a${padding[1]}`
      // return "https://cdn.weippstore.com/c4ca4238a0b923820dcc509a6f75849b/e083dac70f5d97eb74951682a68e4309.png"
    },
    cost() {
      //预计花费
      switch (this.outputsize) {
        case 1024:
          return 250;
        case 512:
          return 225;
        case 256:
          return 160;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.preview{
  display: block;
  img{
    width:100%;
  }
}
.place-holder{
  display: block;
  width :100%;
  height: 0;
  padding-bottom:100%;
  background-color: rgba(0,0,0,.1)
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 192px;
  height: 192px;
  line-height: 192px;
  text-align: center;
}

.avatar {
  width: 192px;
  height: 192px;
  display: block;
}
#previewCavars {
  display: block;
  width: 192px;
  height: 192px;
}
</style>