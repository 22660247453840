import request from '@/utils/request'

export function get_image(imgId) {//获取图像
    ///fieyingchat/gptimage/image
    return request({
        url: `/gptimage/image`,
        method: 'get',
        params:{imgId}
    })
}

export function image_variation(formData) {//图像变换
    return request({
        url: `/gptimage/variation`,
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData,
    })
}

export function ImageVariation(formData){//图像变化带有轮询
    
    return new Promise((reslove,reject)=>{
        //请求接口
        const sub_request = (imgId,times,dur)=>{//这里是轮询逻辑
            if(times>0){
                setTimeout(()=>{//轮训执行
                    get_image(imgId).then(({data})=>{//正常
                        if(data){
                            console.log(data)
                            reslove(data)
                        }else{//再次轮训
                            sub_request(imgId,--times,dur)
                        }
                    }).catch(reject)
                },dur)
            }else{//等待超时
                reject()
            }
        }
        image_variation(formData).then(({data})=>{//成功创建任务
            let image_id = data['id']
            sub_request(image_id,4*5,5000)
        }).catch(reject)
    })


}