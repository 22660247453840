function dataURLToBlob(dataURL) {
    const parts = dataURL.split(',');
    const type = parts[0].split(':')[1].split(';')[0];
    const data = parts[1];
    const byteString = atob(data);
    const length = byteString.length;
    const buffer = new Uint8Array(length);

    for (let i = 0; i < length; i += 1) {
        buffer[i] = byteString.charCodeAt(i);
    }

    return new Blob([buffer], { type });
}
class ImagePreprocess {
    constructor(canvarsId, max_size, optMediatype) {//创建画布
        this.max_size = max_size || 1024  //最大尺寸
        this.optMediatype = optMediatype || "image/png"
        this.canvarsId = canvarsId
        this.dCanvars = document.getElementById(canvarsId)
        // this.ResetCanvas(this)//初始化
    }
    ResetCanvas(size) {//重制画布
        this.dCanvars.width = size
        this.dCanvars.height = size
        var ctx = this.dCanvars.getContext("2d");
        ctx.clearRect(0, 0, size, size);
    }
    LoadFile(file) {//加载文件对象并输出base64
        return new Promise((reslove, reject) => {
            const canvas = this.dCanvars
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.addEventListener('load', e => {//读取成功
                const dataURL = e.target.result;
                const img = new Image();
                img.src = dataURL;
                img.addEventListener('load', () => {//图片加载完成
                    let result = this.ReSize(img)
                    reslove(result)
                });
            })
        })
    }
    ReSize(img) {//调整图片尺寸
        let w = img.width
        let h = img.height
        let max_size = Math.max(w, h)
        if (max_size > this.max_size) {
            this.size = this.max_size
        } else {
            this.size = max_size
        }
        this.ResetCanvas(this.size)
        let scale = this.size / max_size
        let [sw, sh] = [w * scale, h * scale]
        let mask = [(this.size - sw) / 2, (this.size - sh) / 2, sw, sh]
        const ctx = this.dCanvars.getContext('2d');
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(0, 0, this.size, this.size);
        ctx.drawImage(img, ...mask);
        let b64Url = this.dCanvars.toDataURL()
        let file_blob = dataURLToBlob(b64Url)
        return {
            padding: mask,
            file_blob: file_blob
        };
    }
}
export default ImagePreprocess